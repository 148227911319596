
/*
Login & SignUp Pages
*/

.log-wrapper{
  margin:"32px auto 37px";
  max-width:"530px";
  background-color:"#fff";
  padding:"30px";
  border-radius:"10px";
  box-shadow:"0px 0px 10px 10px rgba(0,0,0,0.15)";
}

/*
Login & SignUp Pages
*/
@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url("http://www.fontsaddict.com/fontface/jameel-noori-nastaleeq.ttf");
}

@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url("./fonts/JameelNooriNastaleeqRegular.ttf");
}


		.sba{
      position: relative;
			color: black;  font-family: 'Jameel noori nastaleeq',serif; 
      font-weight: 600; 
      unicode-bidi: embed;text-align:right;
			direction: rtl;
      font-size: 20px;
      max-width: 8.3in;
      max-height: 11.7in;
		}
    .sba .studata div{padding-bottom: 5px;}
    .sba img{
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }

    @media all {
      .page-break {
        display: none;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
      
        display: block;
        page-break-before: always;
      }
    }
    
